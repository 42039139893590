///////////////////////////////imports////////////////////////
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap");
///////////////////////////////Variables//////////////////////
$primaryColor: #34e47f;
$accentColor: #14aa91;
$secondaryColor: #ffe200;

$activeBtnGradientColor: linear-gradient(180deg,
    rgba(255, 232, 0, 1) 0%,
    rgba(255, 193, 0, 1) 100%);
$inactiveBtnGradientColor: linear-gradient(180deg,
    rgba(36, 36, 36, 1) 0%,
    rgba(2, 2, 2, 1) 100%);
$inactiveBtnGradientBorderColor: #1a1a1a;
$warningColor: #ff3d44;
$generalColor: #fff;
$deactiveColor: #fdbe1b;
$noticeColor: rgba(255, 255, 255, 0.6);
$boxBgColorLighter: rgba(0, 0, 0, 0.3);
$boxBgColor: rgba(0, 0, 0, 0.5);
$modalBgColor: rgba(0, 0, 0, 1);
$overlayBgColor: rgba(0, 0, 0, 0.7);

$boxesBorderSize: 2px;
$generalBorderSize: 1px;

$primaryFontFamily: Roboto, arial;
$primaryFontColor: #fff;

$xlFontSize: 60px;
$xlLineHeight: 70px;
$xlFontWeight: 900;
$lgFontSize: 30px;
$tableth1: 40px;
$lgLineHeight: 36px;
$lgFontWeight: 700;
$mdh1FontSize: 40px;
$mdFontSize: 24px;
$mdLineHeight: 30px;
$mdh1LineHeight: 45px;
$mdFontWeight: 400;
$smFontSize: 26px;
$smLineHeight: 30px;
$smFontWeight: 300;
$xsFontSize: 20px;
$xsh5FontSize: 16px;
$xsLineHeight: 26px;
$xsh5LineHeight: 20px;
$xsFontWeight: 100;
$xxsFontSize: 16px;
$titleFontSize: 24px;
$titleLineHeight: 26px;
$titleFontWeight: 500;
$headerTitleFontSize: 90px;
$headerTitleLineHeight: 96px;
$headerTitleFontWeight: 900;
$customSize: 35px;

//////////////////////////////Mixins//////////////////////////
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin in_ie {

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    @content;
  }

  @media all and (-ms-high-contrast: none) {
    @content;
  }

  @supports (-ms-ime-align: auto) {
    @content;
  }
}

//////////////////////////////Placeholders////////////////////
%modalBox {
  background-color: $secondaryColor;
  border: 0;
  color: $modalBgColor;
}

%overBox {
  background-color: $boxBgColor;
  border: $boxesBorderSize solid $generalColor;
  color: $generalColor;
}

%idleBox {
  background-color: $boxBgColorLighter;
  border: $boxesBorderSize solid transparent;
  color: $generalColor;
}

%filledIdleBox {
  @extend %idleBox;
  background-color: $accentColor;
}

%primaryBox {
  border: none;
  background-color: $accentColor;
  color: $generalColor;
}

%activeBoxGradient {
  border: 3px solid $secondaryColor;
  background: $activeBtnGradientColor;
  color: $overlayBgColor;
}

%inactiveBoxGradient {
  border: 3px solid $inactiveBtnGradientBorderColor;
  background: $inactiveBtnGradientColor;
  color: $generalColor;
}

%selectedBox {
  background-color: rgba($secondaryColor, 0.25);
  border: $boxesBorderSize solid $secondaryColor;
  color: $generalColor;
}

%inactiveBox {
  background-color: transparent;
  border: $boxesBorderSize solid $primaryColor;
  color: $primaryColor;
}

%darkInactiveBox {
  background-color: transparent;
  border: $boxesBorderSize solid $accentColor;
  color: $accentColor;
}

%darkBox {
  background-color: $boxBgColorLighter;
  border: none;
  color: $generalColor;
}

%wrongBox {
  background-color: $warningColor;
  border: $boxesBorderSize solid $generalColor;
  color: $generalColor;
}

%button {
  font-size: 20px;
  font-family: $primaryFontFamily;
  font-weight: $mdFontWeight;
  padding: 10px 20px;
}

%smallButton {
  font-size: 20px;
}

%mediumButton {
  font-size: 20px;
}

%squareButton {
  @include size(50px);
  padding: 0 10px;
}

%multipleChoiceButton {
  font-size: $xlFontSize;
  font-family: $primaryFontFamily;
  font-weight: $mdFontWeight;
}

%box {
  border: $generalBorderSize solid $noticeColor;
  padding: 10px;
}

%primaryText {
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
}

///////////////////////////////Typography////////////////////
h {
  &1 {
    @extend %primaryText;
    font-size: $xlFontSize;

    font-weight: $lgFontWeight;
    line-height: $xlLineHeight;
    text-shadow: 7px 7px 10px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px;

    @media screen and (max-width: 767px) {
      font-size: $smFontSize !important;
      line-height: $smLineHeight !important;
    }

    @media screen and (max-width: 768px) {
      font-size: $tableth1;
      line-height: $lgLineHeight;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: $lgFontSize;
      line-height: $mdh1LineHeight;
    }
  }

  &2 {
    @extend %primaryText;
    font-size: $lgFontSize;
    font-weight: $lgFontWeight;
    line-height: $lgLineHeight;
    text-shadow: 7px 7px 10px rgba(0, 0, 0, 0.1);
  }

  &3 {
    @extend %primaryText;
    font-size: $lgFontSize;
    font-weight: $mdFontWeight;
    line-height: $mdLineHeight;

    &.bigger {
      font-size: $customSize;
    }

    @media screen and (max-width: 767px) {
      font-size: $smFontSize !important;
      line-height: $smLineHeight;
    }
  }

  &4 {
    @extend %primaryText;
    font-size: $lgFontSize;
    font-weight: $xsFontWeight;
    line-height: $lgLineHeight;
    padding: 20px 0 20px 0;

    @media screen and (max-width: 767px) {
      font-size: $xsh5FontSize;
      line-height: $xsh5LineHeight;
    }
  }

  &5 {
    @extend %primaryText;
    font-size: $mdFontSize;
    font-weight: $xsFontWeight;
    line-height: $mdLineHeight;

    @media screen and (max-width: 767px) {
      font-size: $xsh5FontSize;
      line-height: $xsh5LineHeight;

    }
  }
}

p {
  @extend %primaryText;
  font-weight: $mdFontWeight;
  font-size: $xsFontSize;
  line-height: $mdLineHeight;

  &.smaller {
    font-weight: $xsFontWeight;
    font-size: $xsFontSize;
    line-height: $xsLineHeight;
  }

  &.xxs-size {
    font-size: $xxsFontSize;
    font-weight: $xsFontWeight;
    line-height: $xsLineHeight;
  }
}

.notice {
  color: $noticeColor;
}

.title {
  font-weight: $titleFontWeight;
  font-size: $titleFontSize;
  line-height: $titleLineHeight;
}

.header-title {
  font-weight: $headerTitleFontWeight;
  font-size: $headerTitleFontSize;
  line-height: $headerTitleLineHeight;
}

.active-text {
  color: $primaryColor;
  font-weight: $smFontWeight;
}

.deactive-text {
  color: $deactiveColor;
  font-weight: $smFontWeight;
}

//////////////////////////////Inputs////////////////////////
.over-button {
  @extend %overBox;
  @extend %button;
}

.modal-button {
  @extend %modalBox;
  @extend %button;
  border: 0;
}

.selected-button {
  @extend %selectedBox;
  @extend %button;
  padding: 10px 10px;
  font-size: 18px;
  margin: 10px 0 10px 0;

  @media screen and (max-width: 768px) {
    margin: 4px 0 4px 0;
    font-size: 14px;
  }
}

.idle-button {
  @extend %idleBox;
  @extend %button;
  padding: 10px 10px;
  font-size: 18px;
  margin: 10px 0 10px 0;

  @media screen and (max-width: 768px) {
    margin: 4px 0 4px 0;
    font-size: 14px;
  }
}

.filled-idle-button {
  @extend %filledIdleBox;
  @extend %button;
}

.primary-button {
  @extend %primaryBox;
  @extend %button;
}

.active-btn-gradient {
  @extend %activeBoxGradient;
  @extend %button;
  font-weight: $xlFontWeight;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 4px;
    background-color: #f68502;
    position: absolute;
    bottom: -7px;
    left: -3px;
    right: -3px;
  }

  i.arrow {
    border-color: $overlayBgColor;
    left: -6px;
  }
}

#add-player-button {
  @media screen and (max-width: 760px) {
    width: 100%;
    margin: 0;
  }
}

.inactive-btn-gradient {
  @extend %inactiveBoxGradient;
  @extend %button;
  font-weight: $lgFontWeight;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 4px;
    background-color: #000000;
    border-color: #1A1A1A;
    position: absolute;
    bottom: -7px;
    left: -3px;
    right: -3px;
  }

  i.arrow {
    border-color: $generalColor;
  }
}

.inactive-btn-gradient,
.active-btn-gradient,
.disable-button {
  @media screen and (max-width: 767px) {
    min-width: 60px;
    justify-content: center;
    margin: 10px;

    &::after {
      text-align: center;
    }

    span {
      display: none;
    }

    i.arrow {
      margin: 0;
    }
  }
}

.dark-button {
  @extend %darkBox;
  @extend %button;
}

.inactive-button {
  @extend %inactiveBox;
  @extend %button;
}

.disable-button {
  @extend %activeBoxGradient;
  @extend %button;
  border-color: #FFF400;
  background: #cec012;
  color: $generalColor;
  font-weight: $xlFontWeight;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 4px;
    background-color: #f68502;
    position: absolute;
    bottom: -7px;
    left: -3px;
    right: -3px;
  }

  i.arrow {
    border-color: $generalColor;
  }
}

.dark-inactive-button {
  @extend %darkInactiveBox;
  @extend %button;
}

.small-button {
  @extend %smallButton;
}

.medium-button {
  @extend %mediumButton;
}

.square-button {
  @extend %squareButton;
}

.over-multiple-choice-button {
  @extend %overBox;
  @extend %multipleChoiceButton;
}

.idle-multiple-choice-button {
  @extend %idleBox;
  @extend %multipleChoiceButton;
}

.inactive-multiple-choice-button {
  @extend %inactiveBox;
  @extend %multipleChoiceButton;
}

.wrong-multiple-choice-button {
  @extend %wrongBox;
  @extend %multipleChoiceButton;
}

.text-input {
  @extend %overBox;
  border-width: $boxesBorderSize;
  background-color: rgba(0, 0, 0, 0) !important;
  display: block;
  font-size: $mdFontSize;
  padding: 10px 35px;
  width: 100%;
  height: auto;
  border-radius: 0;

  &::placeholder {
    color: $generalColor;
    opacity: 0.5;
  }

  &:focus {
    color: #fff;
    border-color: #fff;
  }

  &::-ms-input-placeholder {
    color: $generalColor;
    opacity: 0.5;
  }
}

.admin-input {
  padding: 7px;
  font-size: 16px;
  border-width: 1px;
}

.dashed-input {
  @extend %idleBox;
  border-width: $generalBorderSize;
  display: block;
  font-size: $mdFontSize;
  height: 100%;
  padding: 10px 35px;
  width: 100%;
  border-style: dashed;

  &::placeholder {
    color: $generalColor;
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: $generalColor;
    opacity: 0.5;
  }
}

.forceNoPadding {
  padding: 0 !important;
  line-height: 20px !important;
}

///////////////////////////////Boxes//////////////////////////
.idleBox {
  @extend %idleBox;
}

///////////////////////////////Alerts/////////////////////////
.wrong-alert {
  @extend %wrongBox;
  border-width: 0;
}

//////////////////////////////Resets//////////////////////////
input,
button {
  &:focus {
    outline: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $generalColor;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

img {
  max-width: 100%;
}

//////////////////////////////Dropdown////////////////////////
div .dropdown-control,
div .dropdown-control:hover,
div .dropdown-control:focus,
div .show .dropdown-control.dropdown-toggle {
  @extend %overBox;
  border-width: $boxesBorderSize;
  background-color: transparent;
  display: block;
  font-size: $mdFontSize;
  padding: 10px 35px;
  width: 100%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  outline: none;
  border-radius: 0px;
  text-align: left;

  &::after {
    position: absolute;
    right: 20px;
    top: 25px;
  }
}

div .dropdown-menu {
  @extend %idleBox;
  color: $generalColor;
  font-size: $xsFontSize;
  border-width: $generalBorderSize;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0px;
  width: 100%;
  text-align: left;
}

div .dropdown-item {
  color: $generalColor;
  padding: 10px 35px;

  &:active {
    background-color: $accentColor;
  }
}

div .dropdown-item.active {
  color: $generalColor;
  background-color: $primaryColor;

  &:hover {
    color: rgb(0, 0, 0);
    background-color: $generalColor;
  }

  &:active {
    color: $generalColor;
    background-color: $accentColor;
  }
}

.admin-dropdown {
  padding: 7px !important;
  font-size: 16px !important;
  border-width: 1px !important;

  &::after {
    top: 20px !important;
  }
}

//////////////////////////////Helpers/////////////////////////
.closeBtn {
  margin: 5rem;

  @media screen and (max-width: 767px) {
    margin: 2.5rem 0rem 0 0;
  }
}


//////////////////////////////General/////////////////////////
:focus {
  outline: none;
}

.video-bg {
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  position: fixed;
}

.logo {
  max-height: 50px;
}

.logoBig {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 400px;
}

.ha {
  position: absolute;
}

body {
  background: url(../assets/img/Inscription_background.png);
  background-color: white;
  background-size: cover;
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
  //min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  transform: translate3d(0, 0, 0);

  /* Allows content to fill the viewport and go beyond the bottom */
  height: 100%;


  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
}

.blur {
  filter: blur(25px);
  background-color: rgba($color: #000000, $alpha: 0.2);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.max-vh-100 {
  max-height: 100vh;
}

.no-gutters>.row {
  margin: 0;
}

.welcome .wrapper {
  padding-top: 100px;

  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
}

.welcome-part2 {
  @media screen and (max-width: 768px) {
    margin-top: 4rem;
    ;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.status-circle {
  @include size(20px);
  display: inline-block;
  position: relative;

  &.active {
    background: $primaryColor;
    border: 1px solid $noticeColor;
  }

  &.deactive {
    border: 2px solid $deactiveColor;

    &::after {
      content: "";
      position: absolute;
      border: 1px solid $deactiveColor;
      height: 100%;
      transform: rotate(-45deg);
      left: calc(50% - 1px);
    }
  }
}

.main-container {
  height: calc(100% - 220px);
  margin-top: 120px !important;
  padding: 0 20px 0 40px;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: calc(100% - 320px);
    padding: 10px;
  }

  @media screen and (min-width: 2000px) {
    margin-top: 320px !important;
    height: calc(100% - 450px) !important;
  }
}

.main-container.admin-part {
  margin-top: 0 !important;
  height: calc(100% - 100px);
}

.scrollarea-content {
  padding: 0px;
}

.top-bar {
  border-top: 4px solid $primaryColor;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  &::after {
    content: "";
    border-top: 8px solid $primaryColor;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    flex-basis: 100%;
    max-width: 130px;
    height: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.bottom-bar {
  border-bottom: 4px solid $primaryColor;
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;

  &::after {
    content: "";
    border-bottom: 8px solid $primaryColor;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    flex-basis: 100%;
    max-width: 130px;
    height: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

i.check {
  @include size(40px);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    flex: 1 1 initial;
    width: 30px;
    height: 17px;
    border-left: 6px solid;
    border-bottom: 6px solid;
    border-color: inherit;
    transform: rotate(-45deg);

    margin-top: -10px;
  }
}

button {
  i.check {
    @include size(30px);
    margin-left: 10px;

    &::after {
      margin-top: 0;
      width: 26px;
      margin-bottom: 0px;
    }
  }
}

i.arrow {
  border: solid;
  border-width: 0 4px 4px 0;
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 7px;
  position: relative;
  top: 1px;
  border-color: inherit;

  &.arrow-right {
    transform: rotate(-45deg);
  }

  &.arrow-left {
    transform: rotate(135deg);
  }

  &.arrow-up {
    transform: rotate(-135deg);
  }

  &.arrow-down {
    transform: rotate(45deg);
  }
}

.sidebar-container {
  position: absolute !important;
  height: calc(100vh) !important;
  min-width: 400px;
  top: 0px;

  .sidebar {
    background-color: $boxBgColorLighter;
    z-index: 1;
    padding: 15px 30px !important;

    >*:first-child {
      margin-top: 0 !important;
    }
  }
}

.flex-basis-init {
  flex-basis: initial;
}

.flex-basis-100 {
  flex-basis: 100%;
}

// .footer {
//   background-color: $boxBgColor;
//   z-index: 1;
// }

.header-fish-img {
  max-height: 250px;
}

.fish-infos-img {
  max-width: 775px;
}

.horizontal-line {
  border-top: $generalBorderSize solid $generalColor;
  margin: 15px;
}

.container-box {
  @extend %box;
  background-color: $boxBgColor;

  &.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.modal-box {
  @extend %modalBox;
}

.text-container {
  @extend %box;
  border: 2px solid $generalColor;
  max-height: 300px;
  padding: 0;

  &.scrollarea {
    &.scrollarea {
      .scrollbar-container.vertical {
        right: 0px;
        top: 0px;

        .scrollbar {
          opacity: 1;
        }
      }
    }
  }
}

.item-box-check {
  i.check {
    @include size(70px);
    display: flex;
    background-color: $primaryColor;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(35px, -35px);
    z-index: 2;
  }
}

.item-box {
  padding-bottom: 30px;
  overflow: hidden;
  padding-right: 0;

  &.item-modal {
    padding-bottom: 40px;
    padding-top: 2px;
    z-index: 99999;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 10%;
      right: 10%;
      height: calc(100% - 38px);

      @media screen and (max-height: 850px) {
        border: 0;
      }

      @media screen and (max-width: 1200px) {
        border: 0;
      }
    }


    .modal-next-button {
      @extend .active-btn-gradient;
      @extend .small-button;
      padding: 0 25px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 1;

      .arrow {
        margin: 0 0 0 -7px;
      }

      @media screen and (max-width: 768px) {
        transform: translate(-40%, 500%);
      }
    }

    .modal-back-button {
      @extend .active-btn-gradient;
      @extend .small-button;
      padding: 0 25px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      z-index: 1;

      .arrow {
        margin: 0 -5px 0 0;
      }

      @media screen and (max-width: 768px) {
        transform: translate(0%, 500%);
      }
    }
  }

  &.fish-box {
    @media screen and (max-width: 500px) {
      margin-right: 0 !important;
      padding-left: 0;
      padding: 20px;
      overflow: visible;
      margin-left: 0 !important;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0 !important;
      padding-left: 0;
      padding: 20px;
      overflow: visible;
      margin-left: 0 !important;
    }


    .item-box-content {

      @media screen and (max-width: 500px) {
        height: 300px !important;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-left: 0 !important;

        #SmallViewer {
          top: -15vh;
        }
      }

      #SmallViewer {
        @media screen and (max-width: 500px) and (min-height: 800px) {
          top: -10vh;
        }
      }

      @media screen and (max-width: 768px) {
        height: 500px;
        max-width: 400px;
        margin-right: 0;
      }
    }

    .fish-viewer {
      max-width: 100%;
      width: 100%;

      canvas {
        display: block;
        position: absolute;
        left: calc(50% - 140px);
        top: calc(35%);
        transform: translate(-50%, -50%);

        @media screen and (min-width: 1200px) {
          left: calc(50% + 160px);
        }

        @media screen and (min-width: 1700px) {
          left: calc(50% + 90px);
        }
      }
    }
  }

  .fish-model-viewer {
    width: 130%;
    height: 200%;
    min-height: 400px;
    min-width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    --poster-color: rgba(0, 0, 0, 0);
    outline: none;
    --progress-bar-color: rgba(0, 0, 0, 0);

    @media screen and (max-width: 500px) {
      width: 100% !important;
      height: 100% !important;
      // margin-left: -50px !important;
      margin-top: 0;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 120%;
      margin-left: 0% !important;
      margin-top: 0;
    }


  }

  .fish-model-viewer:focus {
    outline: none;
  }

  .fish-model-viewer-small {
    width: 600px;
    height: 350px;
    left: -150px;
    cursor: pointer;
    --poster-color: rgba(0, 0, 0, 0);
    outline: none;
    --progress-bar-color: rgba(0, 0, 0, 0);
  }

  :host([data-js-focus-visible]:focus:not(.focus-visible)),
  :host([data-js-focus-visible]) :focus:not(.focus-visible) {
    outline: none;
  }

  .item-box-content {
    background-color: $boxBgColorLighter;
    border: 1px solid $boxBgColor;
    padding: 20px;
    margin: 0 30px;
    position: relative;
  }
}

.scrollarea-content.container-box-content {
  padding: 30px;
}



.container:focus {
  outline: none;

  .userInput:focus {
    outline: none;
  }
}

.fish-selected-checkbox {
  position: absolute;
  top: 5%;
  left: 4%;
  transform: translate(-50%, -50%);
  height: 53px;
}

.scrollarea.container-box {
  padding: 0;
}

.fish-viewer {
  max-width: 100%;
  max-height: 100%;
  height: 80%;
  min-height: 450px;
  overflow: hidden;
}

/////////////////////////////////Welcome page///////////////
.welcome-col {
  margin-bottom: 40px !important;
  max-width: 700px;

  @media screen and (max-width: 767px) {
    &:last-child {
      //margin-top: 1.5rem;
    }

    .hidden-sm {
      display: none;
    }

    .row.mt-5 {
      //margin-top: 1rem !important;
    }
  }
}

.CtnWrapper {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    margin-top: 9rem !important;
    height: calc(90% - 1rem);
  }

  @media screen and (max-width: 500px) and (min-height: 800px) {
    padding-bottom: 20vh;
  }
}

.CtnWrapperNoTicket {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    margin-top: 2rem !important;
  }

  @media screen and (max-width: 500px) and (min-height: 800px) {
    padding-bottom: 20vh;
  }
}

.registrationCpmt {
  h4 {
    padding: 40px 0 10px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 82px;
  }
}


///////////////// Form's pages //////////////////

@media screen and (max-width: 500px) {

  .fishesCmpt {

    //margin-top: 50px !important;
    h4 {
      font-size: 22px;
      line-height: 25px;
    }
  }

  .modalitiesCpmt {

    // Text spacing
    p {
      margin-bottom: 5px;
    }

    .mt-4 {
      margin-top: 10px !important;
    }

    .h-200 {
      height: 150px !important;
      position: relative;
    }
  }

}

@media screen and (max-width: 500px) {

  // Players
  .playersCpmt {
    &.mt-5 .row {
      margin-left: 0px;
      margin-right: 0px;
    }

    &.pt-5 {
      padding-top: 0 !important;
    }
  }

  .registrationCpmt {
    .smaller.notice {
      font-size: $xsh5FontSize;
      line-height: $xsh5LineHeight;
      color: #fff;
    }
  }

  h3 {
    font-size: 1.2rem !important;
  }

  .mt-5 {
    margin-top: 1.5rem !important;
  }
}

@media screen and (max-width: 1024px) {
  h3 {
    font-size: 1.5rem !important;
  }
}


// black box input
.welcome.wrapper {
  .flex-grow-0.w-100.mt-5 {
    //margin-top: 15vh !important;
  }
}

.welcome.wrapper {
  .container-box {
    @media screen and (max-width: 767px) {

      //padding: 0 !important;
      //padding-top: 1rem;
      .pr-5 {
        //padding-right: 1rem !important;
      }
    }
  }
}



////////////////////////////Footer Component///////////////
@media screen and (max-width: 767px) {
  .medium-button.mr-4 {
    margin-right: 0 !important;
  }

  .btnWrapper {
    margin-bottom: 20px !important;
    z-index: 20;
  }
}


/////////////////////////////////Team Page//////////////////
.active .stage-header {
  background-color: rgba(0, 0, 0, 0.5);
}

.stage-header {
  max-height: 110px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -8px;
  z-index: 1;
  transition: background-color .3s;
  overflow: hidden;

  .logoWrapper {
    img {
      transition: all 1s;
    }
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 1rem;
    padding-bottom: 0;

    .logoWrapper {
      padding: 1rem !important;
      padding-left: 0 !important;

      h4 {
        text-align: left;
      }
    }
  }

  .stage-header-title {
    max-width: 550px;

    @media screen and (max-width: 769px) {
      max-width: 300px;
      overflow: visible;
      // background: url(../assets/img/NewHeader.png) no-repeat;
      // background-size: 110%;
      // background-position: center 14px;
    }

    padding-top: 40px;
    position: relative;
    top: -10px;
    left: -32px;
    z-index: 1;

    &::after {
      content: "";
      background: url(../assets/img/NewHeader.png) no-repeat;
      width: 100%;
      position: absolute;
      height: 135px;
      top: 0;
      left: -22px;
      z-index: -1;

      @media screen and (max-width: 769px) {
        background-size: 100% 100%;
        width: 150%;
        left: -25%;
        top: 15px;
      }
    }

    .stage-header-sides-left {
      perspective: 26px;
      width: 100px;
      height: 100px;
      position: absolute;
      top: -10px;
      transform: rotate(-132deg);
      left: 30px;

      &::before {
        content: "";
        width: 60px;
        height: 6px;
        background-color: rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 0 1px rgba($primaryColor, 0.15);
        position: absolute;
        top: 19px;
        left: 21px;
        transform: rotateX(-71deg) rotateY(0deg);
        z-index: -1;
      }
    }

    .stage-header-sides-right {
      perspective: 26px;
      width: 100px;
      height: 100px;
      position: absolute;
      top: -10px;
      transform: rotate(-228deg);
      right: 30px;

      &::before {
        content: "";
        width: 60px;
        height: 6px;
        background-color: rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 0 1px rgba($primaryColor, 0.15);
        position: absolute;
        top: 19px;
        left: 21px;
        transform: rotateX(-71deg) rotateY(0deg);
        z-index: -1;
      }
    }

    &.admin-side {
      max-width: 1100px;
      padding-top: 45px;

      .active-text {
        font-weight: $titleFontWeight;
      }

      &::after {
        border-top: 95px solid $boxBgColorLighter;
        border-right: 50px solid transparent;
        border-left: 50px solid transparent;
      }

      &::before {
        content: "";
        opacity: 0.3;
        border-top: 75px solid $boxBgColorLighter;
        border-right: 50px solid transparent;
        border-left: 50px solid transparent;
        width: 115%;
        position: absolute;
        top: 0;
        left: -7.5%;
        z-index: -1;
      }
    }

    h3 {
      width: 70%;
      margin: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

////////////////////////////////Fishes Page//////////////////
.fishesCmpt {

  //margin-top: 100px !important;

  .fish-box {
    max-width: 370px;
    text-align: center;
  }

  @media screen and (min-width: 1200px) {
    .fish-box {
      .item-box-content {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .mt-4.pt-5.row {
      .col-md-6 {
        .item-box-content.ml-5 {
          margin-left: 0 !important;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {

    //margin-top: 50px !important;
    .item-box .fish-model-viewer {
      height: 200% !important;
    }
  }
}

.fishes-container {
  // hack for displaying the fishes divs centered to the page
  max-width: 1600px;

  .fish-box:first-child {
    @media screen and (min-width: 1600px) {
      //left: -48px;
    }

    @media screen and (min-width: 1366px) {
      //left: -36px;
    }
  }


  @media screen and (min-width: 767px) and (max-width: 1023px) {
    .item-box-content {
      margin: 0 30px !important;
    }
  }

  @media screen and (max-width: 1600px) {
    margin-left: 0px;
    max-width: 100%;
    flex: 0 0 100%;
  }

  @media screen and (max-width: 500px) {
    h4 {
      font-size: $smFontSize;
      line-height: $smLineHeight;
    }

    .mt-4.pt-5.row {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
}

///////////////////////////Modal//////////////////
.modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1040;

  @include in_ie {
    background: rgba(0, 0, 0, 0.95);
  }

  .scrollarea {
    .scrollarea-content {
      padding: 4em;

      @media only screen and (max-width: 1024px) {
        margin-left: 20px !important;
        margin-right: 20px !important;
      }

      margin-left: 3em !important;
      margin-right: 3em !important;
      overflow: visible;

      @media only screen and (max-width: 768px) {
        padding: 10px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 70px;
      }
    }

    .scrollbar-container.vertical {
      right: 100px;

      @media only screen and (max-width: 768px) {
        right: 10px;
      }
    }

    .modal-close-button {
      position: absolute;
      z-index: 20 !important;
      top: 40px;
      right: 40px;
      z-index: 1;
      background-color: transparent;
    }
  }

  .fish-viewer {
    max-width: 500%;
    height: 25vw;
    min-height: initial;

    @media screen and (max-width: 768px) {
      height: 50vw;
    }

    canvas {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translate(50%, 0);
      z-index: 0;
    }
  }

  .stage-header-title {
    top: 0;
  }

  .fish-info {
    z-index: 1;

    @media screen and (max-width: 768px) {
      padding-left: 4rem;
    }

    @media screen and (max-width: 1024px) {
      .smaller {
        font-size: 16px;
        line-height: 22px;
      }

      .text-left.col-md-11.col {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

/////////////////////////////Good Visit Page////////////////
.map-col {
  background: url(../assets/img/map.png);
  background-size: cover;
  background-position: center center;

  @media screen and (max-width: 768px) {
    display: table;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: 240px;
  }
}

.goodVisitCmpt {

  @media screen and (max-width: 768px) {
    margin-top: 100px !important;
  }

  margin-top: 4rem !important;

  .no-gutters.row {
    flex-wrap: nowrap;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .col-sm-12 {
    width: 100% !important;
  }
}

/////////////////////////////Admin Part/////////////////////
.admin-part {

  .emailInput.is-invalid,
  .namelInput.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('http://www.w3.org/2000/svg');
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .video-bg {
    filter: blur(30px);
  }

  .stage-header {
    .stage-header-title.admin-side {
      &:after {
        background-image: none;
      }
    }
  }

  .stage-header-title::after {
    left: -25px;
  }

  // Header spacing
  .flex-fill.wrapper {
    margin-top: 120px !important;
  }

  // do not display the close btn on admin-part
  .col .closeBtn {
    pointer-events: none;
    display: none;
    flex: 1;

    .cursor-pointer {
      display: none;
    }
  }

  .col.closeBtn {
    .cursor-pointer {
      display: none;
    }
  }

  .addTeamBtn.idle-button {
    z-index: 20;
  }
}

.teamsList .zIndex100 {
  z-index: 100;
}

.teamsList {
  min-height: 100vh;
}

/////////////////////////////////Modals/////////////////////
.dark-overlay {
  background-color: $overlayBgColor;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.blur-overlay {
  background-color: red;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.info-modal {
  @extend %box;
  background-color: $modalBgColor;
  color: $generalColor;
  display: flex;
  font-size: $lgFontSize;
  left: 50%;
  width: 70%;
  padding: 60px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .modal-icon {
    margin: 20px 60px 0 0;
    flex-shrink: 0;
  }

  .modal-title {
    font-weight: $lgFontWeight;
  }

  .modal-text {
    font-weight: $smFontWeight;
  }
}

////////////////////////////////Scrollbar///////////////////

.scrollarea .scrollbar-container.vertical .scrollbar {
  background: $generalColor !important;
  width: 10px !important;
  border-radius: 10px;
}

.scrollarea.main-container .scrollbar-container.vertical .scrollbar {
  opacity: 0.2;
}

.scrollarea .scrollbar-container,
.scrollarea:hover .scrollbar-container {
  opacity: 1 !important;
  width: 10px !important;
  border-radius: 10px;
}

div .scrollarea .scrollbar-container.active,
div .scrollarea .scrollbar-container:hover {
  opacity: 1 !important;
  background: $boxBgColor;
}

////////////////////////////////Progress-bar///////////////////
.progress-bar-row {
  background-color: $boxBgColorLighter;

  .progress-bar-active {
    min-height: 25px;
    border-top: 1px solid $accentColor;
    border-right: 1px solid $accentColor;

    &:nth-child(1) {
      background-color: darken($primaryColor, 30);
    }

    &:nth-child(2) {
      background-color: darken($primaryColor, 25);
    }

    &:nth-child(3) {
      background-color: darken($primaryColor, 20);
    }

    &:nth-child(4) {
      background-color: darken($primaryColor, 15);
    }

    &:nth-child(5) {
      background-color: darken($primaryColor, 10);
    }

    &:nth-child(6) {
      background-color: darken($primaryColor, 5);
    }

    &:nth-child(7) {
      background-color: darken($primaryColor, 0);
      margin-right: 0 !important;
    }
  }
}

//////////////////////////////Data-table//////////////////////
div .ReactTable .rt-tbody .rt-tr-group {
  min-width: 100px;
  height: 60px;
  margin: 8px 0;
  font-size: 18px;
  font-weight: $xsFontWeight;

  .rt-tr {
    border: 1px solid $noticeColor;
    background: $boxBgColorLighter;

    &.selected-row {
      border: 2px solid $primaryColor;
      background: $modalBgColor;
      font-weight: $mdFontWeight;

      .row-delete {
        display: inline;
      }
    }

    .row-delete {
      display: none;
    }
  }
}

div .ReactTable .rt-table {
  overflow: visible;
}

div .ReactTable .rt-td {
  display: flex;
  align-items: center;
}

div .ReactTable .rt-tr {
  margin: 0 0;
}

div .ReactTable .rt-thead.-header {
  box-shadow: none;
  color: $primaryColor;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: $xxsFontSize;

  .rt-tr {
    text-align: left;
  }
}

div .ReactTable .rt-tr .rt-td:nth-child(1) {
  justify-content: center;
  margin-left: 0;
}

div .ReactTable {
  border: none;
  padding: 0;
  min-height: 150px;

  .pagination-bottom {
    position: fixed;
    bottom: 10px;
    right: 3rem;
    padding: 15px;
  }
}

div .ReactTable .-pagination .-btn {
  @extend %button;
  @extend %smallButton;
  border: 0;
  color: $generalColor;
}

.ReactTable .-pagination .input {
  background-color: red;
}

//////////////////////////////
div .DayPicker-Month {
  margin: 0;
  margin-top: 10px;
}

div .DayPicker .DayPicker-Day {
  width: 30px;
  height: 30px;

  &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryColor;
    border: $generalBorderSize solid $generalColor;
  }

  &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
  &.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: rgba($color: #000000, $alpha: 0.5);
  }
}

.DayPicker-wrapper {
  padding-bottom: 5px !important;
}

//////////////////////////
.custom-control {
  padding-left: 2.1rem;
  min-height: 2.1rem;

  .custom-control-input {
    ~.custom-control-label {
      @extend %primaryText;
      font-size: $xxsFontSize;
      font-weight: $xsFontWeight;
      line-height: $xsLineHeight;
      padding-left: 20px;

      @media screen and (max-width: 500px) {
        padding-top: 4px;
      }

      &::after {
        width: 40px;
        height: 40px;
        top: -0.2rem;
        left: -2.4rem;
      }

      &::before {
        width: 30px;
        height: 30px;
        border-radius: 0;
        left: -2.1rem;
        top: 0;
        background-color: transparent;
        border: 2px solid $generalColor;
      }
    }

    &:checked {
      ~.custom-control-label {
        &::before {
          background: $primaryColor;
          border-color: $generalColor;
        }
      }
    }

    &:focus {
      ~.custom-control-label {
        &::before {
          box-shadow: none;
          border-color: inherit;
        }
      }
    }
  }

  &.style-2 {
    .custom-control-input {
      ~.custom-control-label {
        &::before {
          border: 1px solid $generalColor;
        }
      }

      &:checked {
        ~.custom-control-label {
          &::before {
            background: transparent;
            border-color: $generalColor;
          }

          &::after {
            background-image: none;
            border-right: 3px solid $generalColor;
            border-bottom: 3px solid $generalColor;
            width: 10px;
            height: 18px;
            left: -1.3em;
            top: 0.2em;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

}

#SelectedFishGroupViewer {
  @media screen and (max-width: 768px) {
    height: 140% !important;
    min-height: 300px !important;
  }
}

.fish_group_model {
  width: 120%;
  vertical-align: middle;
  min-height: 400px;
  --poster-color: rgba(0, 0, 0, 0);
  --progress-bar-color: rgba(0, 0, 0, 0);
  outline: none;

  @media screen and (max-width: 768px) {
    min-height: 150px;
  }

  @media screen and (min-width: 768px) {
    min-height: 500px;
  }

  @media screen and (min-width: 1921px) {
    min-height: 600px;
  }
}

.fish_model_final {
  width: 100%;
  min-height: 400px;
  --poster-color: rgba(0, 0, 0, 0);
  --progress-bar-color: rgba(0, 0, 0, 0);
  outline: none;

  @media screen and (max-width: 768px) {
    min-height: 150px;
  }

  @media screen and (min-width: 1921px) {
    min-height: 500px;
  }
}
